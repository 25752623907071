// @import "@progress/kendo-theme-default/dist/all.scss";
@font-face {
    font-family: "Roboto-regular";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("assets/font/Roboto-Regular.ttf") format('truetype'); 
  }

  @font-face {
    font-family: "Roboto-bold";
    font-style: normal;
    font-weight: 700 !important;
    font-display: swap;
    src: url("assets/font/Roboto-Bold.ttf");
  }

* {
    padding: 0;
    margin: 0;
    border: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

:focus,
:active {
    outline: none;
}

a:focus,
a:active {
    outline: none;
}

nav,
footer,
header,
aside {
    display: block;
}

html,
body {
    height: 100%;
    width: 100%;
    font-size: 100%;
    line-height: 1;
    font-size: 14px;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-family: 'Roboto-regular';

}

input,
button,
textarea {
    font-family: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a,
a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul li {
    // list-style: none;
}

img {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}

strong {
    font-weight: 700 !important;
    font-family: 'Roboto-bold';
}

body>.k-widget {
    z-index: 15000015;
}

.header1 {
    font-family: "Roboto-regular";
    font-size: 32px;
    line-height: 1.4;
    font-weight: 700;
    margin-bottom: 0;
  }
  
  .header2 {
    font-family: "Roboto-regular";
    font-size: 28px;
    line-height: 1.4;
    font-weight: 700;
    margin-bottom: 0;
  }
  
  .header3 {
    font-family: "Roboto-regular";
    font-size: 24px;
    line-height: 1.4;
    font-weight: 700;
    margin-bottom: 0;
  }
  
  .header4 {
    font-family: "Roboto-regular";
    font-size: 24px;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 0;
  }
  
  .header5 {
    font-family: "Roboto-regular";
    font-size: 20px;
    line-height: 1.6;
    font-weight: 700;
    margin-bottom: 0;
  }
  
  .header6 {
    font-family: "Roboto-regular";
    font-size: 20px;
    line-height: 1.6;
    font-weight: 400;
    margin-bottom: 0;
  }
  
  .title1 {
    font-family: "Roboto-regular";
    font-size: 18px;
    line-height: 1.6;
    font-weight: 500;
  }
  
  .title2 {
    font-family: "Roboto-regular";
    font-size: 18px;
    line-height: 1.6;
    font-weight: 400;
  }
  
  .title3 {
    font-family: "Roboto-regular";
    font-size: 16px;
    line-height: 1.6;
    font-weight: 700;
  }
  
  .title4 {
    font-family: "Roboto-regular";
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
  }
  
  .title5 {
    font-family: "Roboto-regular";
    font-size: 14px;
    line-height: 1.6;
    font-weight: 500;
  }
  
  .title6 {
    font-family: "Roboto-regular";
    font-size: 14px;
    line-height: 1.6;
    font-weight: 400;
  }
  
  .sub_title1 {
    font-family: "Roboto-regular";
    font-size: 12px;
    line-height: 1.6;
    font-weight: 500;
    color: #7f848f;
  }
  
  .sub_title2 {
    font-family: "Roboto-regular";
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
    color: #7f848f;
  }
  
  .sub_title3 {
    font-family: "Roboto-regular";
    font-size: 10px;
    line-height: 1.6;
    font-weight: 700;
    color: #7f848f; //8692BA
  }
  
  .sub_title4 {
    font-family: "Roboto-regular";
    font-size: 10px;
    line-height: 1.6;
    font-weight: 400;
    color: #7f848f;
  }
  
  .commonP {
    font-family: "Roboto-regular";
    font-size: 13px;
    line-height: 1.6;
    font-weight: 400;
    color: #000000;
  }

.message_layout,
.permission_layout {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 10px;
    z-index: 50000;

}

.message_warning,
.message_success,
.message_error {
    transition: 0.8s;
    position: relative;
    right: 0;
    width: 100%;
    animation-name: message_animation;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.message_warning {
    background: rgba(240, 194, 29, 0.9);
}

.message_success {
    background: rgba(96, 156, 48, 0.9);
}

.message_error {
    background: rgba(233, 91, 91, 0.9);
    display: flex;
    align-items: center;

}

.main_warning {
    top: 0px;
}

@keyframes message_animation {
    0% {
        right: -100%;
    }

    100% {
        height: unset;
        right: 0;
        color: white;
        padding: 10px 20px;
        // background: transparent;
    }
}

/*Statuses*/
.winStatus {
    color: white !important;
    padding: 2px 6px;
    background-color: #0867D7;
    border: 1px solid #0867D7;
    border-radius: 4px;
    font-size: 13px !important;
    text-align: center;
    width: 38px;
    margin: auto;
}

.betStatus {
    color: #B82424 !important;
    padding: 2px 6px;
    background-color: white;
    border: 1px solid #B82424;
    border-radius: 4px;
    font-size: 13px !important;
    text-align: center;
    width: 38px;
    margin: auto;
}

.superbetStatus {
    color: white !important;
    padding: 2px 6px;
    background-color: #B82424;
    border: 1px solid #B82424;
    border-radius: 4px;
    font-size: 13px !important;
    text-align: center;
    width: 38px;
    margin: auto;
}

.betStatusGreen {
    color: #609C30 !important;
    padding: 2px 6px;
    background-color: white;
    border: 1px solid #609C30;
    border-radius: 4px;
    font-size: 13px !important;
    text-align: center;
    margin: auto;

}

.depositStatus {
    color: white !important;
    padding: 2px 6px;
    background-color: #609C30;
    border: 1px solid #609C30;
    border-radius: 4px;
    font-size: 13px !important;
    text-align: center;
    width: 68px;
    margin: auto;
}

.bonus_withdrawStatus {
    color: #CBA006 !important;
    padding: 2px 6px;
    // background-color: white;
    // border: 1px solid #CBA006;
    border-radius: 4px;
    font-size: 13px !important;
    text-decoration: underline;
    text-align: center;

}

.bonus_depositStatus {
    color: #609C30 !important;
    padding: 2px 6px;
    // background-color: white;
    // border: 1px solid #609C30;
    border-radius: 4px;
    font-size: 13px !important;
    text-decoration: underline;
    text-align: center;

}

.chargeback {
    color: #C46018 !important;
    padding: 2px 0px;
    // background-color: white;
    // border: 1px solid #609C30;
    border-radius: 4px;
    font-size: 13px !important;
    text-decoration: underline;
    text-align: center;

}

.bonus_release {
    color: #0771ED !important;
    padding: 2px 6px;
    // background-color: white;
    // border: 1px solid #609C30;
    border-radius: 4px;
    font-size: 13px !important;
    text-decoration: underline;
    text-align: center;

}

.withdrawStatus {
    color: white !important;
    padding: 2px 6px;
    background-color: #CBA006;
    border: 1px solid #CBA006;
    font-size: 13px !important;
    border-radius: 4px;
    text-align: center;
    width: 83px;
    margin: auto;
}

.rollbackStatus {
    color: white !important;
    padding: 2px 6px;
    background-color: #7F848F;
    ;
    border: 1px solid #7F848F;
    ;
    border-radius: 4px;
    font-size: 13px !important;
    text-align: center;
    width: 83px;
    margin: auto;
}

.cancel_withdrawStatus {
    color: #46527D !important;
    padding: 2px 6px;
    // background-color: white;
    // border: 1px solid #46527D;    ;
    border-radius: 4px;
    font-size: 13px !important;
    text-decoration: underline;
    text-align: center;


}

.refundStatus {
    color: white !important;
    padding: 2px 6px;
    background-color: #B82424;
    border: 1px solid #B82424;
    border-radius: 4px;
    font-size: 13px !important;
    text-align: center;
    width: 68px;
    margin: auto;
}

/* Icons */

.ik-pencil-blue {
    background-image: url('./assets/icons/pencil.svg');
}

.ik-pin {
    background-image: url('./assets/icons/Icons/pin.svg');
}

.ik-pin-grey {
    background-image: url('./assets/icons/pin_grey.svg');
}

.ik-randomSort {
    background-image: url('./assets/icons/Icons/dashicons_randomize.svg');
}

.ik-refresh {
    background-image: url('./assets/icons/Icons/refresh.svg');
}

.ik-formio_loader {
    background-image: url('./assets/icons/Icons/fromio_loader.svg');
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 10005000;
    top: 25%;
    left: 50%;
    margin-left: -8px;
}

.ik-refresh-icon:hover {
    background-image: url('./assets/icons/Icons/refresh_hover.svg');
}




.ik-right_stop {
    background-image: url('./assets/icons/Icons/right_stop.svg');
}

.ik-sortAlph {
    background-image: url('./assets/icons/Icons/teenyicons_sort-alphabetically-outline.svg');
}

.ik-refresh-icon {
    cursor: pointer;
    // margin-left: 15px;
    background-image: url('./assets/icons/Icons/refresh2.svg');
    width: 32px;
    height: 32px;
    display: block;
    background-size: cover;
}

.ik-refresh {
    background-image: url('./assets/icons/Icons/refresh.svg');
}

.ik-remove {
    background-image: url('./assets/icons/Icons/remove.svg');
}

.ik-remove-red {
    background-image: url('./assets/icons/Icons/remove.svg');
}


.k-i-sort-asc-sm,
.k-i-sort-desc-sm {
    color: #2883EF !important;
}
.formio-component {
    :where(.mb-2) {
        margin-bottom: 0;
      }
      
      :where(.my-2) {
        margin-top: 0;
        margin-bottom: 0;
      }
}

